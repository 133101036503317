import { Table } from "antd";
import { decoderStatusTrackTableColumns } from "./helper";
import Aries from "../../locale/en-Us.json";
import images from "../../components/images/images";
import ButtonGroupFooter from "../../components/buttonGroupFooter";
import {
  downloadBase64File,
  isPrivateOrInternationalCompany,
  tableLoading,
} from "../../utils/utils";
import API from "../../utils/api/api";
import { useDispatch } from "react-redux";
import { setNotification } from "../../redux/reducers";
import { notificationTypes } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import DecoderDataUploadModal from "../../components/model/decoderDataUploadModal";
import {
  auditorUploadSheetViewJson,
  decoderTrialBalanceSheetJson,
} from "../../stepesJsons/decoder";

export const DecoderStatusTrackSheetTable = ({
  backStep,
  nextStep,
  handleOpenSheet,
  uploadDecoderSheet,
  decoderTrackerData,
  companyName,
  projectId,
  redirect,
  editAllowed,
  setState,
  getTrailBalanceMapping,
  companyType,
  uploadTrialBalanceSheet = () => {},
  getDraftSheetsData,
  setActiveSheetTab,
  financialYear = "",
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showFlowSelectModal, setShowFlowSelectModal] = useState(false);

  const exportDecoderSheet = async () => {
    const url = `v1/decoder/${projectId}/decoder/download`;
    const response = await API({
      method: "GET",
      path: url,
      dispatch,
    });
    const file = downloadBase64File(response?.data);
    if (file) {
      dispatch(
        setNotification({
          type: notificationTypes.SUCCESS,
          message: "File Downloaded Successfully",
          placement: "bottom",
        })
      );
    }
  };

  const finishDecoderProcess = () => {
    navigate("/");
    dispatch(
      setNotification({
        type: notificationTypes.SUCCESS,
        message: Aries.DECODER_TOAST_FINALIZE_SUCCESS,
        placement: "bottom",
      })
    );
  };

  const tablesConfig = [
    decoderTrackerData?.trialBalance && {
      title: "Trial Balance",
      dataSource: decoderTrackerData?.trialBalance,
      type: "TRIAL_BALANCE",
      buttonText: decoderTrackerData?.trialBalance?.[0]?.action,
    },
    {
      title: "Balance Sheets",
      dataSource: decoderTrackerData?.balanceSheets,
      type: "BALANCE_SHEET",
      buttonText: decoderTrackerData?.balanceSheets?.[0]?.action,
    },
    {
      title: "Income Statements",
      dataSource: decoderTrackerData?.incomeStatements,
      type: "INCOME_STATEMENT",
      buttonText: decoderTrackerData?.incomeStatements?.[0]?.action,
    },
    // Additional tables can be added here
  ].filter(Boolean); // Remove any `undefined` entries

  const buttonGroup = {
    left: [
      {
        text: Aries.BUTTON_GLOBAL_DOWNLOAD_FINALIZED_OUTPUT,
        className: "white-button",
        fn: exportDecoderSheet,
        imageLink: images["download.svg"],
      },
    ],
    right: [
      {
        text: Aries.BUTTON_GLOBAL_BACK,
        className: "back-button",
        fn: backStep,
        imageLink: images["arrow-left.svg"],
        disabled: redirect,
      },
      {
        text: Aries.BUTTON_GLOBAL_FINISH,
        className: "primary-button",
        fn: finishDecoderProcess,
        disabled: !decoderTrackerData?.finishEnabled,
        rightImageLink: images["arrow-right.svg"],
      },
    ],
  };

  const getColumns = (type, buttonText) => {
    const baseColumns = decoderStatusTrackTableColumns(handleOpenSheet, type);

    return baseColumns.map((column) => {
      if (column.key === "action") {
        return {
          ...column,
          render: (_, record) => {
            const isAuditorUpload = record.source === "Auditor Uploaded";
            if (type === "TRIAL_BALANCE") {
              return (
                <button
                  className="primary-button-border button"
                  onClick={() => {
                    getTrailBalanceMapping(projectId, record.date);
                    setState((prevRecord) => ({
                      ...prevRecord,
                      step: 8,
                      clickFromTracker: true,
                      jsonSteps: decoderTrialBalanceSheetJson,
                    }));
                  }}
                >
                  {buttonText || "Upload Data"} {/* Dynamic text */}
                </button>
              );
            }

            if (decoderTrackerData?.trialBalance && isAuditorUpload) {
              return (
                <button
                  className="primary-button-border button"
                  onClick={() => {
                    getDraftSheetsData(
                      projectId,
                      isPrivateOrInternationalCompany(companyType) ? record.date : ""
                    );
                    setActiveSheetTab(
                      type === "BALANCE_SHEET"
                        ? "balanceSheet"
                        : "incomeStatementSheet"
                    );
                    setState((prevRecord) => ({
                      ...prevRecord,
                      step: 4,
                      jsonSteps: auditorUploadSheetViewJson,
                    }));
                  }}
                >
                  {buttonText || "Auditor Upload Action"} {/* Dynamic text */}
                </button>
              );
            }

            if (
              record.docType !== "UNAUDITED_FINANCIAL_STATEMENTS" &&
              isPrivateOrInternationalCompany(companyType) &&
              isAuditorUpload
            ) {
              return (
                <button
                  className="primary-button-border button"
                  onClick={() => {
                    getDraftSheetsData(
                      projectId,
                      isPrivateOrInternationalCompany(companyType) ? record.date : ""
                    );
                    setActiveSheetTab(
                      type === "BALANCE_SHEET"
                        ? "balanceSheet"
                        : "incomeStatementSheet"
                    );
                    setState((prevRecord) => ({
                      ...prevRecord,
                      step: 4,
                      jsonSteps: auditorUploadSheetViewJson,
                      financialYear: record.date,
                    }));
                  }}
                >
                  {buttonText || "Auditor Upload Action"} {/* Dynamic text */}
                </button>
              );
            }

            // Default rendering behavior
            return column.render ? column.render(_, record) : null;
          },
        };
      }
      return column;
    });
  };

  return (
    <>
      <div className="decoder-track-header">
        <div className="decoder-track-header-title">Financials Decoder</div>
        <div className="decoder-engament-text">
          {" "}
          Engagement: <span>{companyName}</span>
        </div>
      </div>
      <div className="decoder-track-table-wrapper">
        {tablesConfig?.map((table, index) => (
          <div key={index}>
            <div className="decoder-track-heading-wrapper">
              <h2>{table.title}</h2>
              <div className="button-group-wrapper">
                {index === 0 && (
                  <>
                    <button
                      className="button primary-button"
                      //onClick={uploadDecoderSheet}
                      onClick={() => {
                        setShowFlowSelectModal(true);
                        setState((prevState) => ({
                          ...prevState,
                          clickFromTracker: false,
                        }));
                      }}
                      disabled={!editAllowed}
                    >
                      {companyType.toLowerCase() === "private"
                        ? "Upload Data"
                        : Aries.DECODER_UPLOAD_DATA_BUTTON}
                    </button>
                    {!isPrivateOrInternationalCompany(companyType) && (
                      <button className="button primary-button" disabled>
                        Check SEC for more files
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="decoder-track-table">
              <Table
                dataSource={table?.dataSource}
                columns={getColumns(table.type, table.buttonText)}
                pagination={false}
                rowHoverable={false}
                className={"table-container"}
                loading={tableLoading({
                  loading: decoderTrackerData ? false : true,
                  text: Aries.DECODER_TRACKER_LOADER,
                })}
                rowKey={(record) => record.docId}
                scroll={{ x: "1230px" }}
              />
            </div>
          </div>
        ))}
      </div>
      <DecoderDataUploadModal
        showModel={showFlowSelectModal}
        handleClose={() => setShowFlowSelectModal(false)}
        uploadTrialBalanceSheet={uploadTrialBalanceSheet}
        uploadDecoderSheet={uploadDecoderSheet}
        setState={setState}
        companyType={companyType}
        projectId={projectId}
      />
      <ButtonGroupFooter
        buttonGroupObject={buttonGroup}
        className={"fixed-button-group"}
      />
    </>
  );
};

import React, { memo, useEffect, useState, useRef } from "react";
import { createRoot } from "react-dom/client";
import isEqual from "lodash/isEqual";
import {
  SpreadsheetComponent,
  getIndexesFromAddress,
  getCell,
} from "@syncfusion/ej2-react-spreadsheet";
import { Empty, Skeleton, Popover } from "antd";
import { ReactTyped } from "react-typed";
import "./sheetComponent.scss";
import { syncfusionSpreedSheetBaseUrl } from "../api/api";
import { focus } from "@syncfusion/ej2-react-spreadsheet";
import API from "../../utils/api/api";
import FallbackPlaceholder from "../../utils/components/fallback";
import { Select } from "antd";
import images from "../images/images";
import { imagesIconarr } from "./signImages";
const { Option } = Select;
function SheetComponent({
  getValueOnClick = () => {},
  getCellIndexValue = () => {},
  handleSaveSheetCb = () => {},
  file,
  spreadsheetRef,
  loading = true,
  loadingMessage = "",
  className = "",
  onOpenActionCb = () => {},
  handleHideRowsOnSheetOpen = () => {},
  beforeSaveCb = () => {},
  saveCompleteCb = () => {},
  showExportButton = false,
  activeSheet = () => {},
  showBottomTabs = true,
  sheetIndex = 0,
  enableVirtualization = false,
  loadingsheetStatus = () => {},
  disableMenu = true,
  highlightCellValue = [1, 1],
}) {
  const [isSheetOpening, setIsSheetOpening] = useState(false);
  const [openFileResult, setOpenFileResult] = useState(null);
  const [openFailed, setOpenFailed] = useState(false);
  const abortControllerRef = useRef(null);

  useEffect(() => {
    if (file && file.file) {
      setOpenFileResult(null);
      openExcel({
        file: file.file,
        extension: "xlsx",
        password: "",
      });
    }
  }, [file?.file]);

  useEffect(() => {
    highlightCell(highlightCellValue[0], highlightCellValue[1]);
  }, [highlightCellValue[0], highlightCellValue[1]]);

  const onClick = (args) => {
    let valuesArray = [];
    let allValuesArray = [];
    let index = getIndexesFromAddress(args.range);
    spreadsheetRef.current
      ?.getData("A" + (index[0] + 1) + ":" + "Z" + (index[0] + 1))
      .then((data) => {
        const displayTextsArray = [...data.values()].map((entry, i) => {
          const rowIndex = index[0];
          const colIndex = i;

          const cell = getCell(
            rowIndex,
            colIndex,
            spreadsheetRef.current?.getActiveSheet()
          );
          const displayText = spreadsheetRef.current?.getDisplayText(cell);
          return displayText || entry.value;
        });

        const valuesArray = displayTextsArray.filter(
          (value) => value !== undefined
        );
        getValueOnClick(valuesArray);
        getCellIndexValue(index, args, displayTextsArray); // Pass all values (including undefined if needed)
      });
  };

  const onCreated = async () => {
    if (!spreadsheetRef.current) return;
    await new Promise((resolve) => setTimeout(resolve, 100));

    if (openFileResult?.data?.Workbook?.sheets) {
      spreadsheetRef.current.openFromJson({ file: openFileResult.data });
      handleSaveSheetCb({ action: "openSheet" }, getSheetFromJson);
    }

    spreadsheetRef.current.activeSheetIndex = 0;
    spreadsheetRef.current.showSheetTabs = showBottomTabs;
    spreadsheetRef.current.allowEditing = disableMenu;
    spreadsheetRef.current.enableRibbonTabs(["Home", "Formula", "View"], false);
    focus(spreadsheetRef.current.element);
  };

  const openExcel = async (requestData) => {
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      const abortController = new AbortController();
      abortControllerRef.current = abortController;
      setIsSheetOpening(true);
      const url = syncfusionSpreedSheetBaseUrl + "open";
      const result = await API({
        method: "POST",
        path: url,
        data: JSON.stringify(requestData),
      });
      setOpenFileResult(result || null);
      setIsSheetOpening(false);
      loadingsheetStatus(false);
    } catch (error) {
      setIsSheetOpening(false);
      loadingsheetStatus(true);
      console.error("Error in GET request:", error);
    }
  };
  const getSheetFromJson = async () => {
    const json = await spreadsheetRef.current.saveAsJson();
    return json;
  };

  const EmptyView = () => {
    return (
      <div className="loading-sheet">
        <div className="loading-skeleton">
          <Empty />
        </div>
      </div>
    );
  };

  const highlightCell = (rowIndex, colIndex) => {
    if (spreadsheetRef.current) {
      const sheet = spreadsheetRef.current;
      const cellAddress = `${String.fromCharCode(65 + colIndex)}${rowIndex + 1}`;
      sheet.cellFormat({ backgroundColor: "#fff" });
      sheet.cellFormat({ backgroundColor: "#E6FBF6" }, cellAddress);
      sheet.goTo(cellAddress);
      sheet.selectRange(cellAddress);
    }
  };

  return (
    <div tabIndex="1" className={`spredSheet-container ${className}`}>
      {openFileResult ? (
        <SpreadsheetComponent
          select={onClick}
          ref={spreadsheetRef}
          actionComplete={(args) => handleSaveSheetCb(args, getSheetFromJson)}
          saveComplete={(args) => saveCompleteCb(args, spreadsheetRef)}
          beforeSave={(eventArgs) => beforeSaveCb(eventArgs, spreadsheetRef)}
          fileMenuBeforeOpen={() =>
            spreadsheetRef.current?.enableFileMenuItems(
              ["New", "Open", "Print"],
              false,
              false
            )
          }
          created={onCreated}
          openComplete={onOpenActionCb}
          dataBound={handleHideRowsOnSheetOpen}
          scrollSettings={{ isFinite: true }}
          allowScrolling={true}
        />
      ) : loading || isSheetOpening ? (
        <div className="loading-sheet">
          <div className="loading-skeleton">
            <Skeleton active />
            <ReactTyped
              strings={[loadingMessage]}
              typeSpeed={25}
              style={{ color: "var(--black-color)" }}
            />
            <Skeleton active />
          </div>
        </div>
      ) : openFailed ? (
        <FallbackPlaceholder />
      ) : (
        <EmptyView />
      )}
    </div>
  );
}
export default memo(SheetComponent, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);

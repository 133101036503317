export const DEFAULT_LOADING_TEXT =
  "Please wait, while system is fetching data...";

export const EMPTY_ACTIVITY_CONFIG = {
  hour: 0,
  minute: 0,
  second: 0,
  totalSeconds: 0,
  activity: null,
  engagementName: null,
  clientName: null,
  startTime: null,
  endTime: null,
  cdate: null,
  stage: null,
};

export const EMPTY_LOADER_CONFIG = {
  type: "",
  showLoader: false,
};

export const EMPTY_NOTIFICATION_CONFIG = {
  type: "",
  message: "",
  placement: "",
  description: "",
};

export const notificationTypes = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
};

export const loaderTypes = {
  FULL_SCREEN: "fullScreen",
};

export const notificationIcons = {
  SUCCESS: "check-circle",
  ERROR: "close-circle",
  WARNING: "exclamation-circle",
  INFO: "info-circle",
};

export const tableInputTypes = {
  TEXTAREA: "textarea",
  DATE: "dateInput",
  NUMBER: "numberInput",
  SELECT: "select",
  DATEPICKER: "date",
  TIME: "time",
  AUTOCOMPLETE: "autocomplete",
};
export const commanRegex = /^[A-Za-z0-9 ,.:@+/\\&-]+$/;
export const decoderRegex = /^[A-Za-z0-9 ,.:()$@+/\\&\-–"]+$/;
export const clientRegex = /^[A-Za-z0-9 ,./\\&-]+$/;
export const timeregex = /^[0-9 :]+$/;
export const emailRegex = /^[A-Za-z0-9 ,._@-]+$/;
export const numberRegex = /^[0-9,.]+$/;
export const phoneRegex = /^[+\d\s\-()]+$/;

export const microAppPath = {
  Onboarding: "/onboarding",
  Budgeting: "/budgeting",
  Staffing: "/staffing",
  AuditPlanner: "/audit-planner",
  DocumentAnalyzer: "/analyzer",
  Policies: "/policies",
  Decoder: "/decoder",
  Ratios: "/ratios",
  Variance: "/variance",
  Materiality: "/materiality",
  AuditPrograms: "/programs-programs",
  AuditReport: "/audit-report",
  Review: "/audit-review",
  ClosingProcess: "/audit-closure",
};

export const designationOrderMap = {
  All: 10,
  "Managing Director": 9,
  "Managing Partner": 8,
  Partner: 7,
  "Tax Partner": 6,
  "Audit Manager": 5,
  "Audit Staff": 4,
  "Audit Analyst": 3,
  Staff: 2,
  Admin: 1,
};

export const alertDataTypes = {
  TITLE: "title",
  MESSAGE: "message",
  ON_PRIMARY_BUTTON_CLICK: "onPrimaryButtonClick",
  ON_SECONDARY_BUTTON_CLICK: "onSecondaryButtonClick",
  PRIMARY_BUTTON_TEXT: "primaryButtonText",
  SECONDARY_BUTTON_TEXT: "secondaryButtonText",
  SHOW: "show",
  TYPE: "type",
};

export const EMPTY_ALERT_DATA = {
  title: "",
  message: "",
  onPrimaryButtonClick: () => {},
  onSecondaryButtonClick: () => {},
  primaryButtonText: "",
  secondaryButtonText: "",
  show: false,
  type: "",
};

export const EMPTY_GENERIC_ERROR_DATA = {
  title: "",
  message: "",
  primaryButtonText: "",
  error: false,
  type: "",
};
export const PROJECT_ID = "projectId";

export const statusDisplayMap = {
  TO_BE_STARTED: "TO BE STARTED",
  IN_PROGRESS: "IN PROGRESS",
  DONE: "DONE",
  REVIEW: "REVIEW",
  WIP: "WIP",
};

export const FILETYPE_PROGRAMS_pdf = ["pdf"];
export const FILETYPE_PROGRAMS_xlsx = ["xlsx"];
export const FILETYPE_DOC_PDF = ["doc", "docx", "PDF"];
